import Navbar from 'components/Navbars/AppNav';
import React from 'react';


const Header = () => {
  return (
    <header className="style-3 overflow-hidden" data-scroll-index="0">
      <div className="container">
      <div className="content ">
          <div className="row align-items-center gx-0">
            <div className="col-lg-6">
              <div className="info" style={{ background: '#00000000'  }}>
               
                <h1  style={{color: '#fff'}} className="h1 wow fadeInUp "> Have a look at the beautiful
                 <br /> memories we created </h1>
                
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img mt-4 mt-lg-0 wow fadeIn">
                <img src="/assets/img/header/3d_vector_head8.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">      
        
        <img src="/assets/img/about/style_3_1.png" alt="" />   
      </div>
    </header>
    
  )
}

export default Header